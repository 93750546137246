<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="bs-shadow-none">
      <div class="d-flex bs-justify-content-between bs-gap-3 pa-4 pa-sm-6">
        <div class="d-flex flex-column">
          <h3 class="font-weight-bold">Transactions</h3>
          <div class="grey--text mt-2">Transaction history list.</div>
        </div>

        <v-dialog
          v-if="records && records.length != 0"
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="light"
              :color="modal ? 'primary' : undefined"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-calendar-clock </v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="modal = false"> Cancel </v-btn>
            <v-btn depressed class="primary" @click="filterDate"> Ok </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
      <v-divider></v-divider>
      <v-data-table
        :hide-default-footer="true"
        loading-text="Loading, please wait"
        :headers="headers"
        :items="records"
        :items-per-page="item_per_page_value"
        :loading="recordsLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :page.sync="page"
        @page-count="page_count = $event"
      >
        <template v-slot:item.bet_name="{ item }">
          <span v-if="item.bet_name">
            {{ item.bet_name }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.bet_amount="{ item }">
          <span>{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
        </template>

        <template v-slot:item.game="{ item }">
          <div class="d-flex align-center bs-gap-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.team_a_logo"
                  max-width="24"
                  max-height="24"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{ item.team_a_alias }}</span>
            </v-tooltip>

            <span>VS</span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.team_b_logo"
                  max-width="24"
                  max-height="24"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{ item.team_b_alias }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.bet_at="{ item }">
          <span>{{ formatTheDate(item.bet_at) }}</span>
        </template>

        <template v-slot:item.results="{ item }">
          <div v-if="item.bet_type_id==1">
            <v-chip
              v-if="item.game_status_id"
              small
              :class="
                item.traditional_result.status == 'Won'
                  ? 'green'
                  : item.traditional_result.status == 'Lose'
                  ? 'red'
                  : 'blue'
              "
            >
              {{ item.traditional_result.status }}
            </v-chip>
            <span v-else>-</span>
          </div>
          <div v-else>
            <v-chip
              v-if="item.game_status_id"
              small
              :class="
                betStatus(item.results) == 'Won'
                  ? 'green'
                  : betStatus(item.results) == 'Lose'
                  ? 'red'
                  : 'blue'
              "
            >
              {{ betStatus(item.results) }}
            </v-chip>
            <span v-else>-</span>
          </div>
        </template>

        <template v-slot:item.game_status="{ item }">
          <v-chip
            v-if="item.game_status"
            small
            :class="item.game_status ? 'green white--text' : 'orange white--text'"
          >
            {{ item.game_status }}
          </v-chip>
          <v-chip v-else small class="light"> Not yet started </v-chip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="viewDetails(item)"
                class="d-none d-sm-inline light"
              >
                <v-icon> mdi-card-search-outline </v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>

          <a
            href="#"
            @click.prevent="viewDetails(item)"
            class="d-inline d-sm-none"
          >
            View Details
          </a>
        </template>
      </v-data-table>

      <div v-if="records && records.length != 0">
        <v-divider></v-divider>

        <div class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6">
          <div class="d-flex align-center bs-gap-3">
            <v-select
              :value="item_per_page_value"
              :items="item_per_page"
              outlined
              dense
              label="Items"
              :hide-details="true"
              style="width: 100px"
              @change="item_per_page_value = parseInt($event, 10)"
            ></v-select>
            <div>
              Page
              {{
                page ? page : "0" + "-" + item_per_page_value ? item_per_page_value : "0"
              }}
              of {{ page_count ? page_count : "0" }}
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="page_count"
            :total-visible="0"
          ></v-pagination>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card class="bs-shadow-none" v-if="viewData">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold mb-4 mb-sm-6">Transaction Details</h3>
          <div class="d-flex flex-column bs-gap-2">
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Transaction Date</span>
              <span class="text-end">{{ formatTheDate(viewData.bet_at) }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Combination</span>
              <span class="text-end">{{ viewData.combination_number }}</span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="appData && appData.account_details.agent_id"
            >
              <span class="grey--text">Name</span>
              <span class="text-end" v-if="viewData.bet_name">{{
                viewData.bet_name
              }}</span>
              <span class="text-end">-</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Token</span>
              <span class="text-end">{{
                moneyFormat(parseFloat(viewData.bet_amount))
              }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Game</span>
              <span class="text-end d-flex justify-end bs-gap-2">
                {{ viewData.team_a_name + " " + viewData.team_a_alias }}
                <span>VS</span>
                {{ viewData.team_b_name + " " + viewData.team_b_alias }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Game Schedule</span>
              <span class="text-end">{{ formatTheDate(viewData.game_time) }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Game Status</span>
              <span
                class="text-end"
                v-if="viewData.game_status"
                :class="viewData.game_status ? 'green--text' : 'orange--text'"
              >
                {{ viewData.game_status }}
              </span>
              <span v-else class="grey--text"> Not yet started </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Card Number</span>
              <span class="text-end">{{ viewData.card_number }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Type</span>
              <span class="text-end">{{ viewData.bet_type }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4" v-if="viewData.bet_type_id==1 && viewData.traditional_result">
              <span class="grey--text">Quarter</span>
              <span class="text-end">{{ viewData.quarter }}</span>
            </div>
          </div>

          <div class="grey--text mt-4 mt-sm-6 mb-4">Game Results</div>
          <div class="rounded-lg bs-border overflow-hidden">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th></th>
                  <th>Combination</th>
                  <th>Status</th>
                  <th class="text-end">Prize</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="viewData.bet_type_id==1 && viewData.traditional_result">
                  <td>{{ viewData.traditional_result.quarter }}</td>
                  <td>{{ viewData.traditional_result.winning_combination || '-' }}</td>
                  <td>
                    <span
                      v-if="viewData.game_status_id"
                      :class="
                        viewData.traditional_result.status == 'Won'
                          ? 'green--text'
                          : viewData.traditional_result.status == 'Lose'
                          ? 'red--text'
                          : 'blue--text'
                      "
                    >
                      {{ viewData.traditional_result.status }}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-end">{{ moneyFormat(parseFloat(viewData.traditional_result.prize)) }}</td>
                </tr>
                <tr v-else v-for="item in viewData.results" :key="item.quarter">
                  <td>{{ item.quarter }}</td>
                  <td>{{ item.winning_combination || "-" }}</td>
                  <td>
                    <span
                      v-if="viewData.game_status_id"
                      :class="
                        item.status == 'Won'
                          ? 'green--text'
                          : item.status == 'Lose'
                          ? 'red--text'
                          : 'blue--text'
                      "
                    >
                      {{ item.status }}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-end">{{ moneyFormat(parseFloat(item.prize)) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="d-flex justify-end mt-4 mt-sm-6">
            <v-btn class="light" depressed @click="dialog = false"> Close </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date: "",
    menu: false,
    modal: false,
    sortBy: "bet_at",
    sortDesc: true,

    headers: [
      { text: "Combination", value: "combination_number", class: "text-uppercase" },
      { text: "Token", value: "bet_amount", class: "text-uppercase" },
      { text: "Transaction Date", value: "bet_at", class: "text-uppercase" },
      { text: "Transaction Status", value: "results", sortable: false, class: "text-uppercase" },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      {
        text: "Game Status",
        sortable: false,
        value: "game_status",
        class: "text-uppercase",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
        align: "right",
      },
    ],

    page: 1,
    page_count: 0,
    item_per_page: [5, 10, 20, 40, 80],
    item_per_page_value: 10,

    dialog: false,
    viewData: null,
  }),
  created() {
    this.$store.commit("ending_my_bets/setRecords", []);
    this.changeHeaders();
    this.loadRecords();
  },
  computed: {
    ...mapState("ending_my_bets", ["records", "recordsLoading"]),
    ...mapState(["appData", "accountStatus"]),
    dateFormatted() {
      if (!this.date) {
        return "Filter";
      } else {
        if (this.date == this.today) {
          return "Today";
        } else {
          return formatDate(this.date, "date");
        }
      }
    },
    itemPerPage() {
      if (!this.date) {
        return -1;
      } else {
        return 10;
      }
    },
  },
  methods: {
    ...mapActions("ending_my_bets", ["getRecords"]),
    formatTheDate(theDate) {
      return formatDate(theDate);
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    filterDate() {
      this.$refs.dialog.save(this.date);

      this.loadRecords();
    },
    betStatus(results) {
      if (results.slice(-1)[0].status == "Ongoing") {
        return "Ongoing";
      } else {
        const hasWon = results.filter((result) => result.status == "Won");

        if (hasWon.length) {
          return "Won";
        } else {
          return "Lose";
        }
      }
    },
    changeHeaders() {
      if (
        (this.appData && this.appData.account_details.agent_id) ||
        (this.appData &&
          this.appData.account_details.investor_sub_agent_player_id &&
          this.appData.account_details.investor_sub_type_id != 2 &&
          this.appData.account_details.investor_type_id != 1)
      ) {
        this.headers.splice(1, 0, {
          text: "Name",
          value: "bet_name",
          class: "text-uppercase",
        });
      }
    },
    async loadRecords() {
      if (!this.recordsLoading) {
        const formData = new FormData();
        formData.append("date_start", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },
    viewDetails(item) {
      this.viewData = item;
      this.dialog = true;
    },
  },
  watch: {
    accountStatus(val) {
      if (val) {
        this.changeHeaders();
        this.loadRecords();
      }
    },
  },
};
</script>
