<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="elevation-10">
      <div class="d-flex bs-justify-content-between bs-gap-3 pa-4 pa-sm-6">
        <div class="d-flex flex-column">
          <h3 class="font-weight-bold">Transactions</h3>
          <div class="grey--text mt-2">Transaction history list</div>
        </div>

        <v-dialog
          ref="modal"
          v-model="modal"
          :return-value.sync="date"
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              :color="modal ? 'primary' : undefined"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="modal = false"> Cancel </v-btn>
            <v-btn depressed class="primary" @click="filterDate"> Ok </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <v-divider></v-divider>

      <div class="d-sm-flex justify-sm-end pa-4 pa-sm-6">
        <div class="d-sm-inline-block">
          <v-select
            v-model="game"
            :items="games"
            dense
            outlined
            label="Game"
            hide-details="true"
            item-text="game"
            item-value="slug"
            @change="getBets"
          ></v-select>
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :hide-default-footer="true"
        loading-text="Loading, please wait"
        :headers="headers"
        :items="bets"
        :items-per-page="item_per_page_value"
        :loading="bets_loading"
        :sort-by.sync="sort_by"
        :sort-desc.sync="sort_desc"
        :page.sync="page"
        @page-count="page_count = $event"
      >
        <template v-slot:[`item.bet_name`]="{ item }">
          <span v-if="item.bet_name">
            {{ item.bet_name }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.bet_amount`]="{ item }">
          <span>{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
        </template>

        <template v-slot:[`item.game`]="{ item }">
          <div class="d-flex align-center bs-gap-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.team_a_logo"
                  max-width="24"
                  max-height="24"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{ item.team_a_alias }}</span>
            </v-tooltip>

            <span>VS</span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.team_b_logo"
                  max-width="24"
                  max-height="24"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{ item.team_b_alias }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.bet_at`]="{ item }">
          <span>{{ dateFormat(item.bet_at) }}</span>
        </template>

        <template v-slot:[`item.results`]="{ item }">
          <div v-if="item.bet_type_id == 1 && item.traditional_result">
            <v-chip
              v-if="item.game_status_id"
              small
              class="white--text"
              :class="
                item.traditional_result.status == 'Won'
                  ? 'green'
                  : item.traditional_result.status == 'Lose'
                  ? 'red'
                  : 'blue'
              "
            >
              {{ item.traditional_result.status }}
            </v-chip>
            <span v-else>-</span>
          </div>
          <div v-else>
            <v-chip
              v-if="item.game_status_id"
              small
              class="white--text"
              :class="
                betStatus(item.results) == 'Won'
                  ? 'green'
                  : betStatus(item.results) == 'Lose'
                  ? 'red'
                  : 'blue'
              "
            >
              {{ betStatus(item.results) }}
            </v-chip>
            <span v-else>-</span>
          </div>
        </template>

        <template v-slot:[`item.game_status`]="{ item }">
          <div v-if="$vuetify.breakpoint.mdAndUp">
            <v-chip
              v-if="item.game_status"
              small
              :class="
                item.game_status ? 'green white--text' : 'orange white--text'
              "
            >
              {{ item.game_status }}
            </v-chip>
            <v-chip v-else small class="light"> Not yet started </v-chip>
          </div>
          <div v-else>
            <span
              v-if="item.game_status"
              :class="item.game_status ? 'green--text' : 'orange--text'"
              >{{ item.game_status }}</span
            >
            <span v-else class="grey--text">Not Yet Started</span>
          </div>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="viewDetails(item)"
                class="d-none d-sm-inline"
              >
                <v-icon> mdi-card-search-outline </v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>

          <a
            href="#"
            @click.prevent="viewDetails(item)"
            class="d-inline d-sm-none"
          >
            View Details
          </a>
        </template>
      </v-data-table>

      <div v-if="bets && bets.length != 0">
        <v-divider></v-divider>

        <div
          class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6"
        >
          <div class="d-flex align-center bs-gap-3">
            <v-select
              :value="item_per_page_value"
              :items="item_per_page"
              outlined
              dense
              label="Items"
              :hide-details="true"
              style="width: 100px"
              @change="item_per_page_value = parseInt($event, 10)"
            ></v-select>
            <div>
              Page
              {{
                page
                  ? page
                  : "0" + "-" + item_per_page_value
                  ? item_per_page_value
                  : "0"
              }}
              of {{ page_count ? page_count : "0" }}
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="page_count"
            :total-visible="0"
          ></v-pagination>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card class="bs-shadow-none" v-if="details">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold mb-4 mb-sm-6">Transaction Details</h3>
          <div class="d-flex flex-column bs-gap-2">
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Transaction Date</span>
              <span class="text-end">{{ dateFormat(details.bet_at) }}</span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="game != 'power_ball'"
            >
              <span class="grey--text">Combination</span>
              <span class="text-end">{{ details.combination_number }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4" v-else>
              <span class="grey--text">Combination</span>
              <span class="text-end">{{ details.combination }}</span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="game == 'power_ball'"
            >
              <span class="grey--text">Power Ball</span>
              <span class="text-end">{{
                details.power_ball ? details.power_ball : "-"
              }}</span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="appData && appData.account_details.agent_id"
            >
              <span class="grey--text">Name</span>
              <span class="text-end" v-if="details.bet_name">{{
                details.bet_name
              }}</span>
              <span class="text-end" v-else>-</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Token</span>
              <span class="text-end">{{
                moneyFormat(parseFloat(details.bet_amount))
              }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Game</span>
              <span class="text-end d-flex justify-end bs-gap-2">
                {{ details.team_a_name + " " + details.team_a_alias }}
                <span>VS</span>
                {{ details.team_b_name + " " + details.team_b_alias }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Game Schedule</span>
              <span class="text-end">{{ dateFormat(details.game_time) }}</span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Game Status</span>
              <span
                class="text-end"
                v-if="details.game_status"
                :class="details.game_status ? 'green--text' : 'orange--text'"
              >
                {{ details.game_status }}
              </span>
              <span v-else class="grey--text"> Not yet started </span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="game == 'basketball_ending'"
            >
              <span class="grey--text">Card Number</span>
              <span class="text-end">{{ details.card_number }}</span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="game == 'basketball_ending'"
            >
              <span class="grey--text">Type</span>
              <span class="text-end">{{ details.bet_type }}</span>
            </div>
            <div
              class="d-flex justify-space-between bs-gap-4"
              v-if="details.bet_type_id == 1 && details.traditional_result"
            >
              <span class="grey--text">Quarter</span>
              <span class="text-end">{{ details.quarter }}</span>
            </div>
          </div>

          <div class="grey--text mt-4 mt-sm-6 mb-4">Game Results</div>
          <div class="rounded-lg bs-border overflow-hidden">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th></th>
                  <th>Combination</th>
                  <th>Status</th>
                  <th class="text-end">Prize</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="details.bet_type_id == 1 && details.traditional_result"
                >
                  <td>{{ details.traditional_result.quarter }}</td>
                  <td>
                    {{ details.traditional_result.winning_combination || "-" }}
                  </td>
                  <td>
                    <span
                      v-if="details.game_status_id"
                      :class="
                        details.traditional_result.status == 'Won'
                          ? 'green--text'
                          : details.traditional_result.status == 'Lose'
                          ? 'red--text'
                          : 'blue--text'
                      "
                    >
                      {{ details.traditional_result.status }}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-end">
                    {{
                      moneyFormat(parseFloat(details.traditional_result.prize))
                    }}
                  </td>
                </tr>
                <tr v-else v-for="item in details.results" :key="item.quarter">
                  <td>{{ item.quarter }}</td>
                  <td>{{ item.winning_combination || "-" }}</td>
                  <td>
                    <span
                      v-if="details.game_status_id"
                      :class="
                        item.status == 'Won'
                          ? 'green--text'
                          : item.status == 'Lose'
                          ? 'red--text'
                          : 'blue--text'
                      "
                    >
                      {{ item.status }}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-end">
                    {{ moneyFormat(parseFloat(item.prize)) }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="d-flex justify-end mt-4 mt-sm-6">
            <v-btn class="light" depressed @click="dialog = false">
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import responseGet from "@/helpers/api_request_get.js";
import formatDate from "@/helpers/formatDate";

export default {
  data: () => ({
    bets: [],
    bets_loading: false,
    headers: [
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
      },
      {
        text: "Combination",
        value: "combination_number",
        class: "text-uppercase",
      },
      { text: "Token", value: "bet_amount", class: "text-uppercase" },

      {
        text: "Matchup",
        sortable: false,
        value: "game",
        class: "text-uppercase",
      },
      {
        text: "Game Status",
        sortable: false,
        value: "game_status",
        class: "text-uppercase",
      },
      {
        text: "Transaction Status",
        value: "results",
        sortable: false,
        class: "text-uppercase",
      },
      { text: "Transaction Date", value: "bet_at", class: "text-uppercase" },
    ],
    page: 1,
    page_count: 0,
    item_per_page: [5, 10, 20, 40, 80],
    item_per_page_value: 10,
    sort_by: "bet_at",
    sort_desc: true,
    games: [
      {
        game: "Basketball Ending",
        slug: "basketball_ending",
      },
      {
        game: "Lucky Four",
        slug: "lucky_four",
      },
      {
        game: "Power Ball",
        slug: "power_ball",
      },
    ],
    game: "basketball_ending",
    dialog: false,
    details: null,

    date: "",
    modal: false,
  }),
  mounted() {
    if (this.accountStatus) {
      this.changeHeaders();
    }

    this.getBets();
  },
  computed: {
    ...mapState(["appData", "accountStatus"]),
  },
  methods: {
    async getBets() {
      this.bets_loading = true;

      const form_data = new FormData();
      form_data.append("date_start", this.date);

      const params = new URLSearchParams(form_data);

      switch (this.game) {
        case "basketball_ending":
          {
            const be_res = await responseGet(params, "ending/my_bets");
            if (be_res.status == 200) {
              this.bets = be_res.data.bets;
            }
          }
          break;
        case "lucky_four":
          {
            const lf_res = await responseGet(params, "lucky_four/my_bets");
            if (lf_res.status == 200) {
              this.bets = lf_res.data.bets;
            }
          }
          break;
        case "power_ball":
          {
            const pb_res = await responseGet(params, "power_ball/my_bets");
            if (pb_res.status == 200) {
              this.bets = pb_res.data.bets;
            }
          }
          break;
      }

      this.bets_loading = false;
    },
    filterDate() {
      this.$refs.modal.save(this.date);

      this.getBets();
    },
    viewDetails(item) {
      this.details = item;
      this.dialog = true;
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    dateFormat(date) {
      return formatDate(date);
    },
    betStatus(results) {
      if (results.slice(-1)[0].status == "Ongoing") {
        return "Ongoing";
      } else {
        const has_won = results.filter((result) => result.status == "Won");

        if (has_won.length) {
          return "Won";
        } else {
          return "Lose";
        }
      }
    },
    changeHeaders() {
      if (
        (this.appData && this.appData.account_details.agent_id) ||
        (this.appData.account_details.investor_sub_agent_player_id &&
          this.appData.account_details.investor_sub_type_id != 2 &&
          this.appData.account_details.investor_type_id != 1)
      ) {
        this.headers.splice(1, 0, {
          text: "Name",
          value: "bet_name",
          class: "text-uppercase",
        });
      }
    },
  },
};
</script>
